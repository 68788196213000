import { Container, Typography } from "@mui/material";
import * as React from "react";
import * as communityHubApi from "../../api/community-hub-api";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useHubContent } from "../../api/community-hub-api";
import { HubContentForm } from "./hub-content-form";

export const EditHubContent = () => {
  const { id } = useParams();
  const hubContentQuery = useHubContent();
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  if (hubContentQuery.isLoading) {
    return <div>loading...</div>;
  }

  const content = hubContentQuery.data.find((d) => {
    return d.id.toString() === id;
  });
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Edit Hub Content
      </Typography>
      <div style={{ marginBottom: "10px" }}>
        <Typography variant="h6" gutterBottom component="div">
          Background Image
        </Typography>
        <img src={content.imageUrl} alt={""} />
      </div>
      <HubContentForm
        imagesRequired={false}
        onSubmit={async (values) => {
          const token = await getAccessTokenSilently();
          await communityHubApi.updateHubContent({
            id,
            token,
            ...values,
          });
          history.push("/community_hub/content");
        }}
        defaultValues={{
          title: content.title,
          content: content.content,
          buttonLabel: content.buttonLabel || "",
          buttonTarget: content.buttonTarget || "",
          activeFrom: content.activeFrom ? new Date(content.activeFrom) : null,
          activeTo: content.activeTo ? new Date(content.activeTo) : null,
          lbEnabled: content.lbEnabled,
          goEnabled: content.goEnabled,
          vrEnabled: content.vrEnabled,
          quest3Enabled: content.quest3Enabled,
          quest2Enabled: content.quest2Enabled,
          basicEnabled: content.basicEnabled,
          premiumEnabled: content.premiumEnabled,
          backgroundImage: "",
        }}
      />
    </Container>
  );
};
